import { EModalTypes } from "@constants/modal";
import { ModalLayout } from "@layouts";
import React, { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { ModalContext } from "@contexts";
import { BookingStatus } from "@modules/History/components";
import type { IOrder } from "@interfaces";
import type { EBookingType } from "@constants/history";
import { Dots, Table } from "@ui";
import type { ITableCol } from "@ui/ui.types";
import { axiosInstance } from "@utils";

const cols: ITableCol[] = [
  {
    dataIndex: "changes_type_as_string"
  },
  {
    dataIndex: "created_as_string"
  },
  {
    dataIndex: "name"
  },
  {
    dataIndex: "role",
    render: (role) => <div className={styles.chip}>{role || "n/a"}</div>
  },
  {
    dataIndex: "comment"
  }
];
const heads = [
  { label: "Changes type" },
  { label: "Date change" },
  { label: "Name" },
  { label: "Role made changes" },
  { label: "Comment" }
];
const OrderHistoryModal = (): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const order: IOrder = modalContext?.data?.order;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`crm/entity-history-log?entity_type=order&entity_id=${order.oid}`)
      .then((res) => {
        setData(res.data);
      })
      .finally(() => setLoading(false));
  }, []);
  return (
    <ModalLayout
      className={styles.history}
      type={EModalTypes.ORDER_HISTORY}
      title="History log"
      size="lg"
    >
      {!loading ? (
        <>
          <div className={styles.history__heading}>
            <div className={styles["history__heading-left"]}>
              <p>Booking: №{order?.oid}</p>
              <p>Created: {order?.tm_as_string}</p>
            </div>
            <BookingStatus status={order?.status as EBookingType} />
          </div>

          <Table data={data} cols={cols} heads={heads} />
        </>
      ) : (
        <Dots />
      )}
    </ModalLayout>
  );
};

export default OrderHistoryModal;
