import React, { useContext } from "react";
import cn from "classnames";

import { CardLayout } from "@layouts";
import { Button } from "@ui";
import { ModalContext } from "@contexts";

import { EModalTypes } from "@constants/modal";
import { EBookingType } from "@constants/history";
import BookingStatus from "../BookingStatus";

import type { IBookingProps } from "../components.types";
import type { SyntheticEvent } from "react";

import styles from "./styles.module.scss";
import { getFullUserName } from "@utils";

const Booking = ({ booking, setSelectedOrder }: IBookingProps): JSX.Element => {
  const modalContext = useContext(ModalContext);
  const openModal = (e: SyntheticEvent): void => {
    setSelectedOrder(booking);
  };
  const isWarning = booking.status === EBookingType.SUSPENDED;

  return (
    <CardLayout className={styles.booking} onClick={openModal}>
      <div className={styles.booking__row}>
        <b className={styles.booking__date}>
          {`${booking.tm_as_string}${
            booking.order_pt === 1 ? ` - ${booking.endtm_as_string}` : ""
          }`}
          {booking.has_booking ? (
            <Button
              className={styles.booking__button}
              color="none"
              hSize="h-md"
              onClick={(e) => {
                modalContext?.handleOpen(EModalTypes.TRACK_ORDER, e, {
                  order: booking
                });
              }}
            >
              Track order
            </Button>
          ) : null}
          <Button
            className={styles.booking__button}
            color="none"
            hSize="h-md"
            onClick={(e) => {
              modalContext?.handleOpen(EModalTypes.ORDER_HISTORY, e, {
                order: booking
              });
            }}
          >
            History log
          </Button>
        </b>
        <BookingStatus status={booking.status as EBookingType} />
      </div>
      <div className={styles.booking__row}>
        <div className={styles["booking__row-box"]}>
          <p>
            <b>{getFullUserName(booking.customer)}</b>
          </p>
          <p>{booking.cartype.title}</p>
          <p>From: {booking.from_address}</p>
          {booking.stops.map((el, index) =>
            el !== null ? (
              <p key={index}>
                Stop {index + 1}: {el.place}
              </p>
            ) : null
          )}

          <p>To: {booking.to_address}</p>
        </div>

        <b>№ {booking.oid}</b>
      </div>
      <div className={styles.booking__row}>
        <b className={styles.booking__driver}>
          Driver: {getFullUserName(booking.driver) || "Driver details pending"}
        </b>
        {booking.status === EBookingType.SUSPENDED && (
          <Button className={styles.booking__btn}>Pay</Button>
        )}
      </div>
      {}
      <div className={styles.booking__row}>
        <p className={cn(isWarning && styles.error)}>
          {isWarning
            ? "Payment required"
            : booking.payment_type === "Card"
            ? "Paid by card"
            : "Paid by account"}
        </p>
        <b>{`$${
          booking.status === EBookingType.SUSPENDED
            ? booking.amount_to_pay
            : booking.cost
        }`}</b>
      </div>
    </CardLayout>
  );
};

export default Booking;
